import bookList from "../books.json";
import { menuChoices } from "./enums";
import { BookInfo } from "./models/BookInfo";

export const bannerHeight = "215px";

export const bookImages = [
  "https://jollyjourneypublishing.com/BookImages/TwoLittleBoys-min.jpg",
  "https://jollyjourneypublishing.com/BookImages/SeamoreBook1-min.png",
  "https://jollyjourneypublishing.com/BookImages/SeamoreBook2-min.jpg",
  "https://jollyjourneypublishing.com/BookImages/SeamoreBook3-min.jpg",
  "https://jollyjourneypublishing.com/BookImages/SeamoreBook4-min.png",
  "https://jollyjourneypublishing.com/BookImages/SeamoreBook5-min.png",
];

export const footerHeight = "46px";

export const getBook = (bookId: number) => {
  var book = bookList.find((book) => book.Id === bookId);
  return book as BookInfo;
};

export const getBookList = () => {
  return bookList as BookInfo[];
};

export const getTabName = (selectedTab: number) => {
  switch (selectedTab) {
    case menuChoices.ABOUT:
      return "ABOUT";
    case menuChoices.BIBLEBEDTIMESTORIES:
      return "YOUTUBE";
    case menuChoices.BOOKS:
      return "BOOKS";
  }
};
