import {
  bannerHeight,
  bookImages,
  footerHeight,
  getBookList,
} from "../../shared/SharedFunctions";
import { BookInfo } from "../../shared/models/BookInfo";
import { Box, Card, CardContent, Typography } from "@mui/material";
import GA4React from "ga-4-react";
import IntroducingSeamore from "../../Images/IntroducingSeamore-min.jpg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../shared/windowDimensions";

import styles from "./books.module.scss";

type BooksProps = {
  ga4react: GA4React;
  isMobileDevice: boolean;
};

const Books = ({ ga4react, isMobileDevice }: BooksProps) => {
  const [booksList, setBooksList] = useState([] as BookInfo[]);
  const navigate = useNavigate();
  const [pageContentHeight, setPageContentHeight] = useState("");
  const { screenHeight, screenWidth } = useWindowDimensions();

  useEffect(() => {
    setBooksList(getBookList());
  }, []);

  useEffect(() => {
    setPageContentHeight(
      `calc(${screenHeight}px - 31.5px - ${footerHeight} - ${bannerHeight} - ${footerHeight})`
    );
  }, [screenHeight]);

  return (
    <Box
      bgcolor="#CCCCCC"
      className={styles.pageContent}
      style={{
        //flexDirection: isMobileDevice ? "column" : "row",
        flexWrap: isMobileDevice ? "wrap" : "nowrap",
        height: pageContentHeight,
        justifyContent: "center",
        margin: "0 10px 10px 10px",
        padding: "10px 0",
      }}
    >
      <Box
        bgcolor="#CCCCCC"
        className={styles.bookList}
        sx={{
          justifyContent: "center",
          "&::-webkit-scrollbar": {
            width: "15px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#666666",
            borderRadius: 2,
          },
        }}
      >
        {booksList.map((book) => (
          <Card
            key={book.Id}
            className={styles.cards}
            sx={{
              border: "1px solid #B3B3B3",
              borderRadius: "12px",
              height: "210px",
              margin: "5px 15px 10px 15px",
            }}
          >
            <CardContent
              onClick={() => {
                ga4react.event(
                  `Book Clicked: ${book.Title}`,
                  book.Title,
                  "Click",
                  false
                );
                navigate(`/book/:${book.Id}`);
              }}
              sx={{ display: "flex" }}
            >
              <Box className={styles.card}>
                <img
                  style={{
                    height: "178px",
                    margin: "auto",
                    objectFit: "cover",
                  }}
                  src={bookImages[book.Id]}
                  alt={book.Title}
                />
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
      <Box
        bgcolor="white"
        display="flex"
        flexDirection="column"
        margin="0 10px"
        width="335px"
      >
        <Box bgcolor="#676767" height="335px" margin="0 0 10px 0">
          <video
            autoPlay
            controls
            height="315px"
            loop
            muted
            poster={IntroducingSeamore}
            style={{ margin: "10px" }}
            width="315px"
          >
            <source
              src="https://jollyjourneypublishing.com/BookImages/AboutSeamore.mp4"
              type="video/mp4"
            />
            <p>
              Your browser does not support the video tag. Please click the
              following link to download the video.
            </p>
            <p>
              <a href="/images/AboutSeamore.mp4">AboutSeamore.mp4 download</a>
            </p>
          </video>
        </Box>
        <Typography
          color="#666666"
          fontSize="16px"
          margin="20px 20px 20px 20px"
        >
          What if you met someone that could tell you a Bible story from
          firsthand knowledge?
          <br />
          <br />
          Check out the 'Seamore Adventure' series featuring Seamore, the
          Smottosaurus.
        </Typography>
      </Box>
    </Box>
  );
};

export default Books;
