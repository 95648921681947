import About from "./components/about/About";
import bannerImage from "./Images/BannerImage-min.png";
import BibleBedtimeStories from "./components/bibleBedtimeStories/BibleBedtimeStories";
import Books from "./components/books/Books";
import { Box, Typography } from "@mui/material";
import GA4React from "ga-4-react";
import {
  bannerHeight,
  footerHeight,
  getTabName,
} from "./shared/SharedFunctions";
import logo from "./Images/Logo-min.png";
import { menuChoices } from "./shared/enums";
import MoreBookInfo from "./components/books/MoreBookInfo";
import PageSelections from "./components/pageSelections/PageSelections";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useWindowDimensions from "./shared/windowDimensions";

import styles from "./app.module.scss";

type AppProps = {
  ga4react: GA4React;
};

function App({ ga4react }: AppProps) {
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { screenHeight, screenWidth } = useWindowDimensions();
  const [selectedTab, setSelectedTab] = useState(-1);

  useEffect(() => {
    if (location.pathname.toLocaleLowerCase().startsWith("/book/:")) {
      return;
    }

    switch (location.pathname.toLowerCase()) {
      case "/":
      case "/books":
        if (selectedTab !== menuChoices.BOOKS) {
          setSelectedTab(menuChoices.BOOKS);
        }
        break;
      case "/about":
        if (selectedTab !== menuChoices.ABOUT)
          setSelectedTab(menuChoices.ABOUT);
        break;
      case "/biblebedtimestories":
        if (selectedTab !== menuChoices.BIBLEBEDTIMESTORIES)
          setSelectedTab(menuChoices.BIBLEBEDTIMESTORIES);
        break;
    }
  }, [location]);

  useEffect(() => {
    setIsMobileDevice(screenWidth < 700);
  }, [screenWidth]);

  useEffect(() => {
    if (
      selectedTab === menuChoices.BOOKS &&
      location.pathname !== "/" &&
      location.pathname.toLowerCase() !== "/books"
    ) {
      navigate("/");
    } else if (
      selectedTab === menuChoices.ABOUT &&
      location.pathname !== "/" &&
      location.pathname.toLowerCase() !== "/about"
    ) {
      navigate("/About");
    } else if (
      selectedTab === menuChoices.BIBLEBEDTIMESTORIES &&
      location.pathname !== "/" &&
      location.pathname.toLowerCase() !== "/biblebedtimestories"
    ) {
      navigate("/BibleBedtimeStories");
    }

    if (selectedTab > -1) {
      ga4react.event(
        ("Tab clicked : " + getTabName(selectedTab)) as string,
        getTabName(selectedTab) as string,
        "Click",
        false
      );
    }
  }, [selectedTab]);

  return (
    <Box className={styles.app}>
      <Box bgcolor="#333333" height="46px" />

      <Box display="flex" flexDirection="row">
        <Box height={bannerHeight} width={"fit-content"}>
          <PageSelections
            ga4react={ga4react}
            isMobileDevice={isMobileDevice}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          <Box
            sx={{
              minWidth: isMobileDevice ? "172px" : "238px",
              overflow: "hidden",
              height: "166px",
              width: "fit-content",
            }}
          >
            <img
              style={{
                height: isMobileDevice ? "90px" : "112px",
                margin: isMobileDevice
                  ? "40px auto 5px 7px"
                  : "40px auto 5px 36px",
                objectFit: "contain",
              }}
              src={logo}
              alt="Jolly Journey Publishing Logo"
            />
          </Box>
        </Box>
        <Box margin="0 0 0 auto">
          <img
            style={{
              height: bannerHeight,
              margin: isMobileDevice ? "0 17px -4px auto" : "0 7px -4px auto",
              objectFit: "cover",
            }}
            src={bannerImage}
            alt="Jolly Journey Publishing Bible Stories for Kids"
          />
        </Box>
      </Box>

      <Box className={styles.pageContent}>
        <Routes>
          <Route
            path="/"
            element={
              selectedTab === menuChoices.BOOKS ? (
                <Books ga4react={ga4react} isMobileDevice={isMobileDevice} />
              ) : selectedTab === menuChoices.ABOUT ? (
                <About isMobileDevice={isMobileDevice} />
              ) : (
                <BibleBedtimeStories
                  ga4react={ga4react}
                  isMobileDevice={isMobileDevice}
                />
              )
            }
          />
          <Route
            path="/About"
            element={<About isMobileDevice={isMobileDevice} />}
          />
          <Route
            path="/BibleBedtimeStories"
            element={
              <BibleBedtimeStories
                ga4react={ga4react}
                isMobileDevice={isMobileDevice}
              />
            }
          />
          <Route
            path="/book/:bookId"
            element={
              <MoreBookInfo
                ga4react={ga4react}
                isMobileDevice={isMobileDevice}
              />
            }
          />
          <Route
            path="/Books"
            element={
              <Books ga4react={ga4react} isMobileDevice={isMobileDevice} />
            }
          />
          <Route
            path="*"
            element={
              <Books ga4react={ga4react} isMobileDevice={isMobileDevice} />
            }
          />
        </Routes>
      </Box>

      <Box bgcolor="#333333" className={styles.footer} height={footerHeight}>
        <Typography
          sx={{ color: "#AAAAAA", fontSize: "12px", marginBottom: "2px" }}
        >
          Copyright © 2024 Mud Pie Creations. All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
}

export default App;
