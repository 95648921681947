export const booksInfoChoices = {
  DESCRIPTION: 0,
  LESSONS: 1,
  SCRIPTURES: 2,
};

export const menuChoices = {
  BOOKS: 0,
  BIBLEBEDTIMESTORIES: 1,
  ABOUT: 2,
};
