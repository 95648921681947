import { Box, FormLabel, Menu, MenuItem, Tab, Tabs } from "@mui/material";
import GA4React from "ga-4-react";
import { getTabName } from "../../shared/SharedFunctions";
import { menuChoices } from "../../shared/enums";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";

import styles from "./pageSelections.module.scss";

type PageSelectionsProps = {
  ga4react: GA4React;
  isMobileDevice: boolean;
  selectedTab: number;
  setSelectedTab: Function;
};

const PageSelections = ({
  ga4react,
  isMobileDevice,
  selectedTab,
  setSelectedTab,
}: PageSelectionsProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const isMobileMenuDisplayed = Boolean(menuAnchorEl != null);

  const mobileMenuSelected = (selectedMenu: number) => {
    setSelectedTab(selectedMenu);
    setMenuAnchorEl(null);
  };

  const onDisplaySettingsMenu = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  return (
    <Box width={"fit-content"}>
      {isMobileDevice && (
        <>
          <Box className={styles.mobileMenuBar}>
            <MenuIcon
              className={styles.menuIcon}
              onClick={onDisplaySettingsMenu}
              sx={{ height: "45px", margin: "2px 7px", width: "50px" }}
            />
            <FormLabel
              className={styles.mobileMenuBarText}
              sx={{ color: "inherit", fontSize: "20px", fontWeight: "500" }}
            >
              {getTabName(selectedTab)}
            </FormLabel>
            {/* <Box display='flex' flexDirection='row' alignItems='center' margin='3px 12px 0 auto'>
                        <Link href={'https://www.youtube.com/@cornbreadkids/?sub_confirmation=1'} onClick={() => ga4react.event('Subscribe Button Clicked','Subscribe','Click',false)} target='_blank' rel='noreferrer' underline='none'>
                            <img style={{height:'32px', marginLeft:'20px'}} src={youTubeSubscribe} alt="Subcribe to the Cornbread Kids YouTube channel"/>
                        </Link>
                    </Box> */}
          </Box>

          <Menu
            anchorEl={menuAnchorEl}
            open={isMobileMenuDisplayed}
            onClose={() => setMenuAnchorEl(null)}
          >
            <MenuItem
              sx={{ fontSize: "20px" }}
              onClick={() => mobileMenuSelected(menuChoices.BOOKS)}
            >
              BOOKS
            </MenuItem>
            <MenuItem
              sx={{ fontSize: "20px" }}
              onClick={() =>
                mobileMenuSelected(menuChoices.BIBLEBEDTIMESTORIES)
              }
            >
              YOUTUBE
            </MenuItem>
            <MenuItem
              sx={{ fontSize: "20px" }}
              onClick={() => mobileMenuSelected(menuChoices.ABOUT)}
            >
              ABOUT
            </MenuItem>
          </Menu>
        </>
      )}

      {!isMobileDevice && (
        <Box
          display="flex"
          flexDirection="row"
          height="40px"
          justifyContent="left"
          marginLeft="32px"
        >
          <Tabs
            className={styles.tabs}
            onChange={(event, value) => {
              if (value !== selectedTab) {
                setSelectedTab(value);
              }
            }}
            sx={{
              "& .MuiTab-root": {
                color: "#ADADAD",
                fontFamily: "Calibri",
                fontSize: "18px;",
                fontWeight: "600",
                textTransform: "none",
              },
              "& .MuiTab-root.Mui-selected": {
                color: "#666666",
              },
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#999999",
                marginLeft: `${
                  selectedTab === menuChoices.BOOKS ? "20px" : "14px"
                }`,
                maxWidth: `${
                  selectedTab === menuChoices.BOOKS
                    ? "50px"
                    : selectedTab === menuChoices.BIBLEBEDTIMESTORIES
                    ? "174px"
                    : "152px"
                }`,
                top: "36px",
              },
            }}
            value={selectedTab === -1 ? false : selectedTab}
          >
            <Tab label="Books" />
            <Tab label="Bible Bedtime Stories" />
            <Tab label="Carol Craven Bates" />
          </Tabs>
          {/* <Box display='flex' flexDirection='row' alignItems='center' marginTop='20px'>
                        <Link href={'https://www.youtube.com/@cornbreadkids/?sub_confirmation=1'} target='_blank' rel='noreferrer' underline='none'>
                            <img style={{height:'32px', marginLeft:'20px'}} src={youTubeSubscribe} alt="Subcribe to the Cornbread Kids YouTube channel"/>
                        </Link> */}
          {/* <img style={{height:'48px', margin:'0 0 0 10px'}} src={youTubeShare} alt="Share the Cornbread Kids YouTube channel" /> */}
          {/* </Box> */}
        </Box>
      )}
    </Box>
  );
};

export default PageSelections;
