import ArrowBackIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  bannerHeight,
  bookImages,
  footerHeight,
  getBook,
} from "../../shared/SharedFunctions";
import { BookInfo } from "../../shared/models/BookInfo";
import { Box, Button, FormLabel, Link, Typography } from "@mui/material";
import GA4React from "ga-4-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import useWindowDimensions from "../../shared/windowDimensions";

import styles from "./morebookinfo.module.scss";

type MoreBookInfoProps = {
  ga4react: GA4React;
  isMobileDevice: boolean;
};

const MoreBookInfo = ({ ga4react, isMobileDevice }: MoreBookInfoProps) => {
  let { bookId } = useParams();
  const navigate = useNavigate();
  const [pageContentHeight, setPageContentHeight] = useState("");
  const { screenHeight, screenWidth } = useWindowDimensions();
  const [selectedBook, setSelectedBook] = useState({} as BookInfo);

  useEffect(() => {
    var selectedId = Number(bookId?.substring(1));
    setSelectedBook(getBook(selectedId));
  }, [bookId]);

  useEffect(() => {
    setPageContentHeight(
      `calc(${screenHeight}px - 31.5px - ${footerHeight} - ${bannerHeight} - ${footerHeight})`
    );
  }, [screenHeight]);

  const NewlineText = (text?: string) => {
    if (!text) return <></>;

    var counter = 0;
    var formattedText = text
      .split("\n")
      .map((str) => <p key={++counter}>{str}</p>);
    return formattedText;
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <Box
      bgcolor="#CCCCCC"
      className={styles.pageContent}
      style={{
        height: pageContentHeight,
        justifyContent: "center",
        margin: "0 10px 10px 10px",
        padding: "10px 0",
      }}
    >
      {/* Return Button */}
      <Box
        height="26px"
        margin="5px auto 5px 15px"
        onClick={() => navigate("/books/")}
        sx={{
          color: "black",
          position: "fixed",
          top: `calc(${footerHeight} + ${bannerHeight} + 10px)`,
          "& :hover": {
            color: "#002DB2",
            cursor: "pointer",
          },
        }}
      >
        <FormLabel
          sx={{
            borderRadius: "5px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <ArrowBackIcon sx={{ color: "inherit", height: "32px" }} />
          <Typography
            sx={{ fontSize: "22px", marginLeft: "7px", marginRight: "5px" }}
          >
            Return
          </Typography>
        </FormLabel>
      </Box>

      <Box
        bgcolor="#CCCCCC"
        margin="40px auto -10px auto"
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        sx={{
          alignContent: "start",
          overflowX: "hidden",
          overflowY: "auto",

          "&::-webkit-scrollbar": {
            width: "15px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#666666",
            borderRadius: 2,
          },
        }}
      >
        <Box
          bgcolor="white"
          borderRadius="7px"
          display="flex"
          flexDirection={isMobileDevice ? "column" : "row"}
          margin="10px auto 40px auto"
          overflow-x="hidden"
          padding="10px 10px 30px 10px"
          width="80%"
        >
          <img
            style={{
              border: "2px solid #cccccc",
              margin: "30px auto auto auto",
              objectFit: "cover",
              width: "250px",
            }}
            src={bookImages[selectedBook.Id]}
            alt={selectedBook.Title}
          />
          <Box
            className={styles.moreInfo}
            sx={{
              margin: "0 20px 20px 30px",
            }}
          >
            <FormLabel
              sx={{ fontSize: "22px", fontWeight: "bold", marginTop: "21px" }}
            >
              {selectedBook.Title}
            </FormLabel>
            {selectedBook.Subtitle && (
              <FormLabel sx={{ fontSize: "18px", fontWeight: "bold" }}>
                {selectedBook.Subtitle}
              </FormLabel>
            )}
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              marginTop="14px"
            >
              {selectedBook.BookNumber && (
                <FormLabel sx={{ fontSize: "18px", marginRight: "21px" }}>
                  {`Book ${selectedBook.BookNumber}`}
                </FormLabel>
              )}
              <Button
                onClick={() => {
                  ga4react.event(
                    `Buy Button Clicked: ${selectedBook.Title}`,
                    selectedBook.Title,
                    "Click",
                    false
                  );
                  openInNewTab(selectedBook.BuyLink);
                }}
                sx={{
                  backgroundColor: "#555555",
                  color: "white",
                  width: "150px",
                  "&:hover": {
                    backgroundColor: "#777777",
                  },
                }}
              >
                Buy Book
              </Button>
            </Box>
            <FormLabel
              sx={{
                fontSize: "18px",
              }}
            >
              {NewlineText(selectedBook.Description)}
            </FormLabel>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MoreBookInfo;
