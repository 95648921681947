import { Box } from "@mui/material";
import { bannerHeight, footerHeight } from "../../shared/SharedFunctions";
import carolCravenBates from "../../Images/Carol-Craven-Bates-min.png";
import { useEffect, useState } from "react";
import useWindowDimensions from "../../shared/windowDimensions";

import styles from "./about.module.scss";

type AboutProps = {
  //ga4: any,
  isMobileDevice: boolean;
};

const About = ({ isMobileDevice }: AboutProps) => {
  const [pageContentHeight, setPageContentHeight] = useState("");
  const { screenHeight, screenWidth } = useWindowDimensions();

  useEffect(() => {
    setPageContentHeight(
      `calc(${screenHeight}px - 31.5px - ${footerHeight} - ${bannerHeight} - ${footerHeight})`
    );
  }, [screenHeight]);

  return (
    <Box
      bgcolor="#CCCCCC"
      className={styles.pageContent}
      style={{
        height: pageContentHeight,
        justifyContent: "center",
        margin: "0 10px 10px 10px",
        padding: "10px 0",
      }}
      sx={{
        "&::-webkit-scrollbar": {
          width: "15px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#666666",
          borderRadius: 2,
        },
      }}
    >
      <Box
        bgcolor="white"
        borderRadius="7px"
        display="flex"
        flexDirection={isMobileDevice ? "column" : "row"}
        margin="10px auto 10px auto"
        overflow-x="hidden"
        padding="30px 10px"
        width="80%"
      >
        <Box margin={isMobileDevice ? "15px auto" : "15px auto 15px 15px"}>
          <img
            src={carolCravenBates}
            alt="Carol Craven Bates profile"
            style={{
              border: "2px solid #cccccc",
              borderRadius: "5px",
              height: "150px",
            }}
          />
        </Box>

        <Box
          sx={{
            fontSize: isMobileDevice ? "20px" : "24px",
            margin: "15px 30px auto 30px",
          }}
        >
          <span>
            Carol Craven Bates has been writing children's books since 2009. She
            first became interested in writing for children when she taught
            Sunday School at her church and realized that many of the children
            were completely unaware of some of the most exciting, but lesser
            known, stories in the Bible.
          </span>
          <br />
          <br />
          <span>
            Carol and her husband have two daughters, two sons, and seven
            grandchildren. Through her stories Carol hopes to encourage boys and
            girls to learn to enjoy reading. She chose Christian fiction so
            children would see how exciting the Bible is and how it fits into
            their everyday lives.
          </span>
        </Box>
      </Box>
    </Box>
  );
};

export default About;
